import AppModifier from "./app.modifier"
import AppProvider from "./app.provider"
import {inject, Injectable} from "@angular/core"
import CdkImageInterface from "../../cdk/interface/cdk-image.interface"
import {AuthStateInterface} from "../../application/shared/security/store/auth.state"
import {AppStateInterface} from "../app.state"
import CdkFilterInterface from "../../cdk/interface/sidebar/cdk-filter.interface"
import CdkClientInterface from "../../cdk/interface/cdk-client-interface"
import CdkProductInterface from "@cdk/interface/cdk-product.interface"
import {DetailingStateInterface} from "../../application/ddesmobilize/logged/detailing/store/detailing.state"
import {FavoriteStateInterface} from "../../application/ddesmobilize/logged/favorite/store/favorite.state"
import {
  AnnouncementStateInterface
} from "../../application/ddesmobilize/logged/showroom/announcement/store/announcement.state"

class SidebarStateInterface {
}

@Injectable({
  providedIn: 'root'
})
export default class AppFacade {
  private modifier: AppModifier = inject(AppModifier)
  private provider: AppProvider = inject(AppProvider)

  showLoading(): any {
    return this.modifier.showLoading()
  }

  showContent(): any {
    return this.modifier.showContent()
  }

  hideContent(): any {
    return this.modifier.hideContent()
  }

  hideLoading(): any {
    return this.modifier.hideLoading()
  }

  getLanding(): any {
    return this.provider.landing
  }

  getFavorite(): any {
    return this.provider.favorite
  }

  getDetailing(): any {
    return this.provider.detailing
  }

  getAnnouncement(): any {
    return this.provider.announcement
  }

  getFilter(): any {
    return this.provider.filter
  }

  getSnapshot(): any {
    return this.provider.snapshot
  }

  getAuthorization(): any {
    return this.provider.authorization
  }

  getPayment(): any {
    return this.provider.payment
  }

  setLanding(value: CdkImageInterface[]) {
    this.modifier.landing(value)
  }

  setSidebar(value: SidebarStateInterface) {
    this.modifier.sidebar(value)
  }

  setAuthorization(value: AuthStateInterface) {
    this.modifier.authorization(value)
  }

  setBootstrap(value: AppStateInterface) {
    this.modifier.bootstrap(value)
  }

  setDetailing(value: DetailingStateInterface) {
    this.modifier.detailing(value)
  }

  setAnnouncement(value: AnnouncementStateInterface) {
    this.modifier.announcement(value)
  }

  setFavorite(value: FavoriteStateInterface) {
    this.modifier.favorite(value)
  }

  handleBootstrap() {
    const clientState: CdkClientInterface | false = this.provider.client

    if (clientState)
      clientState?.metadata?.valid
        ? this.modifier.setClient(clientState)
        : this.provider.fetchClient()
  }
}
