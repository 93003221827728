import CdkSessionStorageService from "../../cdk/service/cdk-session-storage.service"
import {inject, Injectable} from "@angular/core"
import {SidebarStateInterface} from "../../application/ddesmobilize/logged/sidebar/store/sidebar.state"
import CdkClientService from "../../cdk/service/cdk-client.service"
import {plainToInstance} from "class-transformer"
import CdkClientModel from "../../cdk/model/cdk-client-model"
import AppModifier from "./app.modifier"
import CdkClientInterface from "../../cdk/interface/cdk-client-interface"
import {AppStateInterface} from "../app.state"
import {DetailingStateInterface} from "../../application/ddesmobilize/logged/detailing/store/detailing.state"
import {FavoriteStateInterface} from "../../application/ddesmobilize/logged/favorite/store/favorite.state"

@Injectable({
  providedIn: 'root'
})
export default class AppProvider {
  private localService: CdkSessionStorageService = inject(CdkSessionStorageService)
  private clientService: CdkClientService = inject(CdkClientService)
  private modifier: AppModifier = inject(AppModifier)

  get landing() {
    return this.localService.obtain('landingState')
  }

  get filter() {
    const sidebarState = this.localService.obtain<SidebarStateInterface>('sidebarState')
    return sidebarState ? sidebarState.filter : false
  }

  get snapshot() {
    const sidebarState = this.localService.obtain<SidebarStateInterface>('sidebarState')
    return sidebarState ? sidebarState.snapshot : false
  }

  get sidebar() {
    return this.localService.obtain('sidebarState')
  }

  get authorization() {
    return this.localService.obtain('authState')
  }

  get payment() {
    return this.localService.obtain('purchaseState')
  }

  get announcement() {
    return this.localService.obtain('announcementState')
  }

  get client(): CdkClientInterface | false {
    const appState = this.localService.obtain<AppStateInterface>('appState')
    return appState ? appState.client : false
  }

  get detailing(): DetailingStateInterface | false {
    const detailingState = this.localService.obtain<DetailingStateInterface>('detailingState')
    return detailingState || false
  }

  get favorite(): FavoriteStateInterface | false {
    const favoriteState = this.localService.obtain<FavoriteStateInterface>('favoriteState')
    return favoriteState || false
  }

  fetchClient() {
    this.clientService.client().subscribe({
      next: client => {
        const context = plainToInstance(CdkClientModel, client, {excludeExtraneousValues: true}) as CdkClientInterface
        context.metadata.valid = true
        this.modifier.setClient(context)
      },
      error: () => {
        this.modifier.setClient(new CdkClientModel)
      }
    })
  }
}
