import CdkClientInterface from "../cdk/interface/cdk-client-interface"

export class ActionSetClient {
  static readonly type = "[App] Set Client"
  constructor(public client: CdkClientInterface) {}
}
export class ActionSetLoadingGlobal {
  static readonly type = "[App] Set Global Loading"
  constructor(public show: boolean) {}
}

export class ActionSetHideContent {
  static readonly type = "[App] Set hide content"
  constructor(public payload: boolean) {}
}
