import {Injectable} from "@angular/core"
import {compress, decompress} from 'lz-string'

@Injectable({
  providedIn: 'root'
})
export default class CdkSessionStorageService {

  store<ContextInterface>(key: string, payload: ContextInterface) {
    const state = JSON.stringify(payload)
    sessionStorage.setItem(key, compress(state))
  }

  obtain<ContextInterface>(key: string): ContextInterface | false {
    try {
      let storage: any = sessionStorage.getItem(key)
      storage = decompress(storage)
      storage = JSON.parse(storage) as ContextInterface
      return storage
    } catch (error) {
      return false
    }
  }

  delete(key: string) {
    let response: boolean = false
    try {
      sessionStorage.removeItem(key)
      response = true
    } catch (error) {
      response = false
    }
    return response
  }

  wipe() {
    sessionStorage.clear()
  }
}
