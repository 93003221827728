import {Expose, Transform, Type} from "class-transformer"
import CdkStateInterface from "../interface/sidebar/cdk-state.interface"
import CdkAddressInterface from "../interface/cdk-address.interface"
import CdkStateModel from "./sidebar/cdk-state.model"

export default class CdkAddressModel implements CdkAddressInterface {

  @Expose()
  @Transform(({obj}) => obj?.Numero)
  number: string = ''

  @Expose()
  @Transform(({obj}) => obj?.cep || obj?.Cep)
  cep: string = ''

  @Expose()
  @Transform(({obj}) => obj?.bairro || obj?.Bairro)
  street: string = ''

  @Expose()
  @Transform(({obj}) => obj?.localidade)
  region: string = ''

  @Expose()
  @Transform(({obj}) => obj?.logradouro || obj?.Endereco)
  settlement: string = ''

  @Expose()
  @Transform(({obj}) => obj?.ibge || obj?.CodigoMunicipio)
  ibge: string = ''

  @Expose()
  @Transform(({obj}) => obj?.uf || obj?.CodigoUf)
  state: CdkStateInterface | Partial<CdkStateInterface> | Pick<CdkStateInterface, 'acronym'> = new CdkStateModel


  @Expose()
  @Transform(({obj}) => obj?.Complemento)
  complement: string = ''
}
