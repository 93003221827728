import {inject, Injectable} from "@angular/core"
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs"
import AppFacade from "../../store/facade/app.facade";

@Injectable({
  providedIn: 'root'
})export default abstract class CdkBaseService<ResponseInterface, EntityInterface> {

  endpoint: string = ""
  private httpClient: HttpClient = inject(HttpClient)

  get(): Observable<ResponseInterface> {
    return this.httpClient.get<ResponseInterface>(this.endpoint)
  }

  put<PayloadInterface>(payload: PayloadInterface): Observable<ResponseInterface> {
    return this.httpClient.put<ResponseInterface>(this.endpoint, payload)
  }

  patch<PayloadInterface>(payload: PayloadInterface): Observable<ResponseInterface> {
    return this.httpClient.patch<ResponseInterface>(this.endpoint, payload)
  }

  post<PayloadInterface>(payload: PayloadInterface): Observable<ResponseInterface> {
    return this.httpClient.post<ResponseInterface>(this.endpoint, payload)
  }

  delete(id: Partial<EntityInterface>): Observable<ResponseInterface> {
    return this.httpClient.delete<ResponseInterface>(this.endpoint)
  }
}
