import CdkImageInterface from "../../cdk/interface/cdk-image.interface"
import CdkSessionStorageService from "../../cdk/service/cdk-session-storage.service"
import {inject, Injectable} from "@angular/core"
import {AuthStateInterface} from "../../application/shared/security/store/auth.state"
import {AppStateInterface} from "../app.state"
import {Dispatch} from "@ngxs-labs/dispatch-decorator"
import CdkClientInterface from "../../cdk/interface/cdk-client-interface"
import {ActionSetClient, ActionSetHideContent, ActionSetLoadingGlobal} from "../app.action"
import DetailingState, {
  DetailingStateInterface
} from "../../application/ddesmobilize/logged/detailing/store/detailing.state"
import {FavoriteStateInterface} from "../../application/ddesmobilize/logged/favorite/store/favorite.state"
import {
  AnnouncementStateInterface
} from "../../application/ddesmobilize/logged/showroom/announcement/store/announcement.state"

class SidebarStateInterface {
}

@Injectable({
  providedIn: 'root'
})
export default class AppModifier {
  private localService: CdkSessionStorageService = inject(CdkSessionStorageService)

  landing(stateContext: CdkImageInterface[]) {
    this.localService.store<CdkImageInterface[]>('landingState', stateContext)
  }

  sidebar(stateContext: SidebarStateInterface) {
    this.localService.store<SidebarStateInterface>('sidebarState', stateContext)
  }

  favorite(stateContext: FavoriteStateInterface) {
    this.localService.store<FavoriteStateInterface>('favoriteState', stateContext)
  }

  authorization(stateContext: AuthStateInterface) {
    this.localService.store<AuthStateInterface>('authState', stateContext)
  }

  bootstrap(stateContext: AppStateInterface) {
    this.localService.store<AppStateInterface>('appState', stateContext)
  }

  detailing(stateContext: DetailingStateInterface) {
    this.localService.store<DetailingStateInterface>('detailingState', stateContext)
  }

  announcement(stateContext: AnnouncementStateInterface) {
    this.localService.store<AnnouncementStateInterface>('announcementState', stateContext)
  }

  @Dispatch()
  showLoading() {
    return new ActionSetLoadingGlobal(true)
  }

  @Dispatch()
  hideLoading() {
    return new ActionSetLoadingGlobal(false)
  }

  @Dispatch()
  showContent() {
    return new ActionSetHideContent(false)
  }

  @Dispatch()
  hideContent() {
    return new ActionSetHideContent(true)
  }

  dropDetailing() {
    this.localService.delete('detailingState')
  }

  dropAnnouncement() {
    this.localService.delete('announcementState')
  }

  @Dispatch()
  setClient(client: CdkClientInterface) {
    return new ActionSetClient(client)
  }
}

