import CdkAddressModel from "./cdk-address-model"
import CdkUserInterface from "../interface/cdk-user-interface"
import {Expose, plainToInstance, Transform} from "class-transformer"
import CdkAddressInterface from "../interface/cdk-address.interface"

export default class CdkUserModel implements CdkUserInterface {

  @Expose()
  @Transform(({obj}) => plainToInstance(CdkAddressModel, obj, {excludeExtraneousValues: true}))
  address: CdkAddressInterface = new CdkAddressModel

  @Expose()
  @Transform(({obj}) => obj.Cpf)
  cpf: string = ''

  @Expose()
  @Transform(({obj}) => obj.Nome)
  name: string = ''

  @Expose()
  @Transform(({obj}) => obj.Rg)
  rg: string = ''

  @Expose()
  @Transform(({obj}) => obj.Cnpj)
  cnpj?: string = ''

  @Expose()
  @Transform(({obj}) => obj.username)
  username: string = ''
}
