import CdkBaseService from "./cdk-base.service"
import {inject, Injectable} from "@angular/core"
import CdkClientInterface from "../interface/cdk-client-interface"
import {Observable, tap} from "rxjs"

@Injectable({
  providedIn: 'root'
})
export default class CdkClientService {
  private httpClientService: CdkBaseService<any, CdkClientInterface> = inject(CdkBaseService)

  client(): Observable<any> {
    this.httpClientService.endpoint = 'https://ipinfo.io/ip'
    return this.httpClientService.get().pipe(tap({
      next: ip => this.clientData(ip)
    }))
  }

  private clientData(client: string): Observable<CdkClientInterface> {
    this.httpClientService.endpoint = `https://ipinfo.io/widget/demo/${client}`
    return this.httpClientService.get().pipe(tap({
      next: client => client,
    }))
  }
}
