import CdkUserModel from "./cdk-user-model"
import CdkUserInterface from "../interface/cdk-user-interface"

export default class CdkClientModel {
  ip: string = "invalid"
  city: string = "invalid"
  metadata = {
    valid: false
  }
  user: CdkUserInterface = new CdkUserModel
}
